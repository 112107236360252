var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('b-row',[_c('div',{staticClass:"border-3 border-right-info p-0",staticStyle:{"width":"14%"}},[_c('div',{staticClass:"navContainer"},[_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'management-settings-type-request' },"exact-active-class":"sub-tab-nav","link-classes":[
            _vm.getRoute('management-settings-type-request')
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("TYPE REQUEST")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'management-settings-merchants' },"exact-active-class":"sub-tab-nav","link-classes":[
            _vm.getRoute('management-settings-merchants')
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("MERCHANTS")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'management-settings-reason-code' },"exact-active-class":"sub-tab-nav","link-classes":[
            _vm.getRoute('management-settings-reason-code')
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("REASON CODE")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'management-settings-time' },"exact-active-class":"sub-tab-nav","link-classes":[
            _vm.getRoute('management-settings-time')
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("DUE TIME & DATE")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'returns-assistance-settings' },"exact-active-class":"sub-tab-nav","link-classes":[
            _vm.getRoute('returns-assistance-settings')
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("RETURN ASSISTANCE")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'gpt-prompts-settings' },"exact-active-class":"sub-tab-nav","link-classes":[
            _vm.getRoute('gpt-prompts-settings')
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("GPT PROMPTS")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'vocabulary-settings' },"exact-active-class":"sub-tab-nav","link-classes":[
            _vm.getRoute('vocabulary-settings') ? 'link-active' : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("AUTOMATIC NOTES: VOCABULARY")])])],1)]),_c('div',{staticClass:"col",staticStyle:{"width":"86%"}},[_c('b-card',{attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name,ref:"routerView"})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }