<template>
  <div class="p-2">
    <b-row>
      <div class="border-3 border-right-info p-0" style="width: 14%">
        <div class="navContainer">
          <b-nav-item
            :to="{ name: 'management-settings-type-request' }"
            exact-active-class="sub-tab-nav"
            :link-classes="[
              getRoute('management-settings-type-request')
                ? 'link-active'
                : 'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              bgTabsNavs,
            ]"
            class="text-left"
          >
            <span class="menu-title">TYPE REQUEST</span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'management-settings-merchants' }"
            exact-active-class="sub-tab-nav"
            :link-classes="[
              getRoute('management-settings-merchants')
                ? 'link-active'
                : 'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              bgTabsNavs,
            ]"
            class="text-left"
          >
            <span class="menu-title">MERCHANTS</span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'management-settings-reason-code' }"
            exact-active-class="sub-tab-nav"
            :link-classes="[
              getRoute('management-settings-reason-code')
                ? 'link-active'
                : 'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              bgTabsNavs,
            ]"
            class="text-left"
          >
            <span class="menu-title">REASON CODE</span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'management-settings-time' }"
            exact-active-class="sub-tab-nav"
            :link-classes="[
              getRoute('management-settings-time')
                ? 'link-active'
                : 'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              bgTabsNavs,
            ]"
            class="text-left"
          >
            <span class="menu-title">DUE TIME & DATE</span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'returns-assistance-settings' }"
            exact-active-class="sub-tab-nav"
            :link-classes="[
              getRoute('returns-assistance-settings')
                ? 'link-active'
                : 'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              bgTabsNavs,
            ]"
            class="text-left"
          >
            <span class="menu-title">RETURN ASSISTANCE</span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'gpt-prompts-settings' }"
            exact-active-class="sub-tab-nav"
            :link-classes="[
              getRoute('gpt-prompts-settings')
                ? 'link-active'
                : 'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              bgTabsNavs,
            ]"
            class="text-left"
          >
            <span class="menu-title">GPT PROMPTS</span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'vocabulary-settings' }"
            exact-active-class="sub-tab-nav"
            :link-classes="[
              getRoute('vocabulary-settings') ? 'link-active' : 'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              bgTabsNavs,
            ]"
            class="text-left"
          >
            <span class="menu-title">AUTOMATIC NOTES: VOCABULARY</span>
          </b-nav-item>
        </div>
      </div>
      <div class="col" style="width: 86%">
        <b-card no-body>
          <router-view ref="routerView" :key="$route.name" />
        </b-card>
      </div>
    </b-row>
  </div>
</template>
  <script>
export default {
  methods: {
    getRoute(route) {
      return route == this.$route.name;
    },
  },
};
</script>
  <style lang="scss" scoped>
.navContainer {
  list-style: none;
}

.link-inactive:hover {
  transition: 0.25s ease;
  & a {
    margin-left: 4px;
  }
  background: #ffffff !important;
  color: #726d84;
}

.link-inactive {
  padding: 10px 15px !important;
  background: #ffffff;
  color: #726d84;
  border-radius: 6px 0px 0px 6px;
}

.link-active {
  padding: 10px 15px !important;
  background: #8f5fe8;
  color: #f8f6fe;
  border-radius: 6px 0px 0px 6px;
}

.dark-layout {
  & .link-inactive {
    padding: 10px 15px !important;
    background: #17171a;
    color: #a2a2a3;
    border-radius: 6px 0px 0px 6px;
  }
  & .link-inactive:hover {
    transition: 0.25s ease;
    margin-left: 4px;
    background: #17171a !important;
    color: #a2a2a3;
  }
  .link-active {
    padding: 10px 15px !important;
    background: #8f5fe8;
    color: #f8f6fe;
    border-radius: 6px 0px 0px 6px;
  }
}

.link-active:hover {
  zoom: 1.03;
}
</style>
  